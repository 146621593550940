import React from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../redux/auth/auth.api";
import { twMerge } from "tailwind-merge";
import { IoMdHome } from "react-icons/io";

interface IPageWrapper {
  loading?: boolean;
  children?: any;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  header?: React.ReactNode;
  showBack?: boolean;
  back?: string;
}

export function PageWrapper({
  title,
  subtitle,
  className,
  header,
  children,
  containerClassName = "",
  showBack = true,
  back,
}: IPageWrapper) {
  const navigate = useNavigate();

  const [logoutUser] = useLogoutMutation();
  return (
    <div className={`h-screen flex flex-col`}>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <div className="bg-gradient-to-r from-indigo-500 to-purple-700 flex flex-col justify-center items-center mb:p-5 md:pb-32 p-2 pb-32">
          <div className="flex w-full items-center justify-between">
            {showBack ? (
              <div className="flex flex-row gap-3">
                <BsArrowLeft
                  onClick={() => {
                    if (back) {
                      navigate(back);
                    } else {
                      navigate(-1);
                    }
                  }}
                  size={40}
                  className="cursor-pointer hover:bg-cyan-500 rounded-full p-2 hover:text-white transition-colors text-white"
                />
                <IoMdHome
                  onClick={() => {
                    navigate("/");
                  }}
                  size={40}
                  className="cursor-pointer hover:bg-cyan-500 rounded-full p-2 hover:text-white transition-colors text-white"
                />
              </div>
            ) : (
              <div></div>
            )}

            <div
              className="text-white flex flex-row items-center gap-3 cursor-pointer "
              onClick={() => {
                logoutUser();
                navigate("/");
              }}
            >
              Logout <AiOutlinePoweroff />
            </div>
          </div>
          {header ? (
            header
          ) : (
            <div className="mt-3 text-center">
              {title && <div className="text-gray-50 md:text-2xl text-lg ">{title}</div>}
              {subtitle && <div className="text-gray-300 text-md">{subtitle}</div>}
            </div>
          )}
        </div>
        <div className={twMerge("p-5 bg-white rounded-t-2xl -mt-16 flex-1", containerClassName)}>
          <div className={className}>{children}</div>
        </div>
      </div>
    </div>
  );
}
