import { Navigate, Outlet } from "react-router-dom";

import { AuthLayout } from "../container";
import { useAppSelector } from "../hooks";
import { RootState } from "../redux/store";

export const AuthRoute = () => {
  const isAuthenticated = useAppSelector((state: RootState) => !!state?.auth.loggedIn);

  return !isAuthenticated ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};
