import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { PageLoader } from "../components";
import { Wrapper } from "../container/Wrapper";

import { AuthRoute } from "./AuthRoute";
import { PrivateRoute } from "./PrivateRoute";

// Lazy Loading Pages
const Home = React.lazy(() => import("../pages/Landing/Home"));
const AboutUs = React.lazy(() => import("../pages/Landing/AboutUs"));
const Courses = React.lazy(() => import("../pages/Landing/Courses"));
const CourseDetails = React.lazy(() => import("../pages/Landing/CourseDetails"));
const HomeMockTests = React.lazy(() => import("../pages/Landing/HomeMockTests"));
const HomeCurrentAffairs = React.lazy(() => import("../pages/Landing/HomeCurrentAffairs"));
const HomeNotes = React.lazy(() => import("../pages/Landing/HomeNotes"));

const RefundPolicy = React.lazy(() => import("../pages/Landing/RefundPolicy"));
const Shipping = React.lazy(() => import("../pages/Landing/Shipping"));
const Privacy = React.lazy(() => import("../pages/Landing/Privacy.jsx"));
const TermsAndConditions = React.lazy(() => import("../pages/Landing/TermsAndConditions"));
const NotFound = React.lazy(() => import("../pages/NotFound"));

// Auth Pages
const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Register"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const VerifyEmail = React.lazy(() => import("../pages/VerifyEmail"));

// Student Pages
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Profile = React.lazy(() => import("../pages/Profile"));
const Payments = React.lazy(() => import("../pages/Payments"));
const ChangePassword = React.lazy(() => import("../pages/ChangePassword"));

// Course & Batch Pages
const CourseList = React.lazy(() => import("../pages/Batch/CourseList"));
const BatchList = React.lazy(() => import("../pages/Batch/BatchList"));
const BatchSingle = React.lazy(() => import("../pages/Batch/BatchSingle"));
const BatchPerformanceDetails = React.lazy(() => import("../pages/Batch/components/BatchPerformanceDetails"));

// Requests & Announcements
const RequestList = React.lazy(() => import("../pages/Request/RequestList"));
const RequestSingle = React.lazy(() => import("../pages/Request/RequestSingle"));
const AnnouncementList = React.lazy(() => import("../pages/Announce/AnnouncementList"));

// Test Pages
const TestStart = React.lazy(() => import("../pages/Test/TestStart"));
const TestAttempt = React.lazy(() => import("../pages/Test/TestAttempt"));
const TestResult = React.lazy(() => import("../pages/Test/TestResult"));
const TestSolution = React.lazy(() => import("../pages/Test/TestSolution"));

function AppRouter() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            {/* Default Route: Redirects "/" to "/home" */}
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="home" element={<Home />} />
            <Route path="home_courses" element={<Courses />} />
            <Route path="mock_tests" element={<HomeMockTests />} />
            <Route path="home_current_affairs" element={<HomeCurrentAffairs />} />
            <Route path="/:title" element={<CourseDetails />} />
            <Route path="home_notes" element={<HomeNotes />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="refund" element={<RefundPolicy />} />
            <Route path="shipping" element={<Shipping />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="tnc" element={<TermsAndConditions />} />

            {/* Authentication Routes */}
            <Route element={<AuthRoute />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="forgot-password/:token" element={<ResetPassword />} />
              <Route path="verify-email" element={<VerifyEmail />} />
            </Route>

            {/* Private Routes: Requires Authentication */}
            <Route element={<PrivateRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="payments" element={<Payments />} />
              <Route path="change-password" element={<ChangePassword />} />

              {/* Course & Batch Routes */}
              <Route path="courses" element={<CourseList />} />
              <Route path="batches">
                <Route index element={<BatchList />} />
                <Route path=":id" element={<BatchSingle />} />
                <Route path=":id/:tab" element={<BatchSingle />} />
                <Route path=":id/:tab/:performance" element={<BatchPerformanceDetails />} />
              </Route>

              {/* Requests & Announcements */}
              <Route path="requests">
                <Route index element={<RequestList />} />
                <Route path=":id" element={<RequestSingle />} />
              </Route>
              <Route path="announcements" element={<AnnouncementList />} />

              {/* Test Routes */}
              <Route path="test">
                <Route path=":batchId/:testId" element={<TestAttempt />} />
                <Route path=":batchId/:testId/:studentTest" element={<TestStart />} />
                <Route path=":batchId/:testId/:studentTest/result" element={<TestResult />} />
                <Route path=":batchId/:testId/:studentTest/solution" element={<TestSolution />} />
              </Route>
            </Route>

            {/* 404 Not Found Page */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Wrapper>
    </BrowserRouter>
  );
}

export default AppRouter;
